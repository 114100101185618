<template>
    <div class="lunbo">
        <el-form ref="mainForm" :model="mainForm" :rules="rules" label-width="80px">
            <el-form-item label="名称" prop="fullName">
                <el-input v-model="mainForm.fullName" style="width:200px"></el-input>
            </el-form-item>
            <!-- <el-form-item label="简介" prop="introduce">
                <el-input type="textarea" v-model="mainForm.introduce" :autosize="true" style="width:600px"></el-input>
            </el-form-item> -->
            <el-form-item label="图标" prop="avatar">
                <imageUploader :imageList="imageList" v-on:success="uploadSucess" :isMultiple="false" :showPreview="true"></imageUploader>
            </el-form-item>
            <el-form-item>
                <el-button @click="goBack">取消</el-button>
                <el-button type="primary" @click="submit">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import imageUploader from "@/components/imageUploader";
export default {
    components: { imageUploader },
    data() {
        return {
            mainForm: {
                fullName: "",
                introduce: "",
                avatar: "",
            },
            rules: {
                title: [{ required: true, message: "请输入姓名", trigger: "blur" }],
                introduce: [{ required: true, message: "请输入简介", trigger: "blur" }],
                avatar: [{ required: true, message: "请上传图片", trigger: "blur" }],
            },
            artistId: null,
            imageList: [],
        };
    },
    filters: {
        getImageUrl: function(src, host) {
            return host + src;
        },
    },
    mounted() {
        // edit
        let artistId = this.$route.query.artistId;
        if (artistId) {
            this.artistId = artistId;
            this.$http({
                url: "/sys/art/getArtist",
                data: {
                    id: artistId,
                },
            }).then((res) => {
                this.mainForm.fullName = res.data.fullName;
                this.mainForm.introduce = res.data.introduce;
                this.mainForm.avatar = res.data.avatar;
                this.imageList = [
                    {
                        name: this.mainForm.avatar,
                        url: this.$cdn() + this.mainForm.avatar,
                    },
                ];
            });
        }
    },
    methods: {
        // 后退
        goBack: function() {
            this.$router.go(-1);
        },
        uploadSucess: function(val) {
            this.mainForm.avatar = val;
        },
        submit: function() {
            this.$refs.mainForm.validate((valid) => {
                if (valid) {
                    if (this.artistId == null) {
                        // create
                        this.$http({
                            url: "/sys/art/createArtist",
                            data: {
                                fullName: this.mainForm.fullName,
                                introduce: this.mainForm.introduce,
                                avatar: this.mainForm.avatar,
                            },
                        }).then((res) => {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                            });
                            this.$router.push("/artist/list");
                        });
                    } else {
                        // update
                        this.$http({
                            url: "/sys/art/updateArtist",
                            data: {
                                id: this.artistId,
                                fullName: this.mainForm.fullName,
                                introduce: this.mainForm.introduce,
                                avatar: this.mainForm.avatar,
                            },
                        }).then((res) => {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                            });
                            this.$router.push("/artist/list");
                        });
                    }
                }
            });
        },
    },
};
</script>
